<template>
  <div>LoyaltyReportsUser</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  name: 'LoyaltyReportsUser',
  data() {
    return {}
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Loyalty Reports User', route: '' },
    ])
  },
  methods: {},
}
</script>
